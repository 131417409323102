import { Injectable } from '@angular/core';
import { Editor, ViewContainerElement, ViewDocumentFragment, ViewElement, ViewNode } from 'ckeditor5';
import type { PositionOptions } from 'ckeditor5/src/utils.js';

@Injectable({
    providedIn: 'root'
})
export class PluginUtilsService {

    constructor() { }

    public getSelectedElementWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
		const view = editor.editing.view;
		const selection = view.document.selection;
		let selectedElement = selection.getSelectedElement();
        if(!selectedElement) {
            const elementAtPosition = selection.getFirstPosition()?.parent;
            if(!elementAtPosition || elementAtPosition?.is('documentFragment')) {
                return null;
            }
            return this.findElementAncestorWithClass( (elementAtPosition as ViewNode), classNameToIdentify );;
        }
		return this.findElementAncestorWithClass( selectedElement, classNameToIdentify );
    }

  public showFakeVisualSelection(editor: Editor, markerName: string): void {
    const model = editor.model;

    model.change( writer => {
      const range = model.document.selection.getFirstRange()!;

      if ( model.markers.has(markerName ) ) {
        writer.updateMarker( markerName, { range } );
      } else {
        if ( range.start.isAtEnd ) {
          const startPosition = range.start.getLastMatchingPosition(
            ( { item } ) => !model.schema.isContent( item ),
            { boundaries: range }
          );

          writer.addMarker( markerName, {
            usingOperation: false,
            affectsData: false,
            range: writer.createRange( startPosition, range.end )
          } );
        } else {
          writer.addMarker( markerName, {
            usingOperation: false,
            affectsData: false,
            range
          } );
        }
      }
    } );
  }

  public hideFakeVisualSelection(editor: Editor, markerName: string): void {
    const model = editor.model;

    if ( model.markers.has( markerName ) ) {
        model.change( writer => {
            writer.removeMarker( markerName );
        } );
    }
}


  public getBalloonPositionData(editor: Editor, classNameInElementToPosition: string, markerName: string): Partial<PositionOptions> {
    const view = editor.editing.view;
    const model = editor.model;
    const viewDocument = view.document;
    let target: PositionOptions[ 'target' ];

    if ( model.markers.has( markerName ) ) {
        // There are cases when we highlight selection using a marker (#7705, #4721).
        const markerViewElements = Array.from( editor.editing.mapper.markerNameToElements( markerName )! );
        const newRange = view.createRange(
            view.createPositionBefore( markerViewElements[ 0 ] ),
            view.createPositionAfter( markerViewElements[ markerViewElements.length - 1 ] )
        );

        target = view.domConverter.viewRangeToDom( newRange );
    } else {
        // Make sure the target is calculated on demand at the last moment because a cached DOM range
        // (which is very fragile) can desynchronize with the state of the editing view if there was
        // any rendering done in the meantime. This can happen, for instance, when an inline widget
        // gets unlinked.
        target = () => {
            const targetSignature = this.getSelectedElementWithClass(editor, classNameInElementToPosition );

            return targetSignature ?
                // When selection is inside signature element, then attach panel to this element.
                view.domConverter.mapViewToDom( targetSignature )! :
                // Otherwise attach panel to the selection.
                view.domConverter.viewRangeToDom( viewDocument.selection.getFirstRange()! );
        };
    }

    return { target };
}

  private findElementAncestorWithClass( position: ViewElement | ViewNode| null, classNameToIdentify: string  ): ViewContainerElement | null {
    return position?.getAncestors({includeSelf: true}).reverse().find( ( ancestor ): ancestor is ViewContainerElement => this.isElementWithClass( ancestor, classNameToIdentify ) ) || null;
  }

  private isElementWithClass( node: ViewNode | ViewDocumentFragment, classNameToIdentify: string ): boolean {
    return node.is( 'containerElement' ) && !!node.hasClass(classNameToIdentify);
  }


}
