export class RadioInputConstants {

    public static readonly CONTAINER_LABEL_DATA_MODEL = 'radio-pg';

    public static readonly CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW = 'AN';
    public static readonly CONTAINER_CLASS_DATA_VIEW = 'rGroup';  //radio-pg
    public static readonly CONTAINER_CLASS_EDITION_VIEW = 'radio-pg';

    public static readonly ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT = 'data-options-count';
    public static readonly ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT = 'data-options-count';
    public static readonly ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT = 'optionsCount';

    public static readonly OPTION_LABEL_DATA_MODEL = 'radio-pg-option';
    public static readonly OPTION_CLASS_EDITION_VIEW = 'rBox';
    public static readonly OPTION_CLASS_DATA_VIEW = 'rBox';

    public static readonly ATTRIBUTE_EDITION_VIEW_OPTION_POSITION = 'data-position';
    public static readonly ATTRIBUTE_DATA_VIEW_OPTION_POSITION = 'data-position';
    public static readonly ATTRIBUTE_DATA_MODEL_OPTION_POSITION = 'position';

    public static readonly INPUT_LABEL_DATA_MODEL = 'radio-pg-check';
    public static readonly INPUT_CLASS_EDITION_VIEW = 'radio-pg-check';
    public static readonly INPUT_CLASS_DATA_VIEW = 'irBox';//'radio-pg-check';
    
    public static readonly ATTRIBUTE_EDITION_VIEW_GROUP_NAME = 'name';
    public static readonly ATTRIBUTE_DATA_VIEW_GROUP_NAME = 'name';
    public static readonly ATTRIBUTE_DATA_MODEL_GROUP_NAME = 'dataName';

    public static readonly DESCRIPTION_LABEL_DATA_MODEL = 'radio-pg-option-description';
    public static readonly DESCRIPTION_CLASS_EDITION_VIEW = 'radio-pg-option-description';
    public static readonly DESCRIPTION_CLASS_DATA_VIEW = 'rhDesc'; //'radio-pg-option-description';

    public static readonly CONTENT_LABEL_DATA_MODEL = 'radio-pg-option-content';
    public static readonly CONTENT_CLASS_EDITION_VIEW = 'radio-pg-option-content';
    public static readonly CONTENT_CLASS_DATA_VIEW = 'radio-pg-option-content'; //No existe....

    public static readonly VISUAL_SELECTION_MARKER_NAME = 'radio-pg';
}
