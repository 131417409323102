import { BubblingEvent, DomEventData, DomEventObserver } from 'ckeditor5';

export default class DoubleClickObserver extends DomEventObserver<'dblclick'> {
	/**
	 * @inheritDoc
	 */
	public readonly domEventType = 'dblclick' as const;

	/**
	 * @inheritDoc
	 */
	public onDomEvent( domEvent: MouseEvent ): void {
		this.fire( domEvent.type, domEvent );
	}
}
export type ViewDocumentDoubleClickEvent = BubblingEvent<{
	name: 'dblclick';
	args: [ data: DomEventData<MouseEvent> ];
}>;

