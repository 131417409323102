import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement, ViewEditableElement } from 'ckeditor5';
import { RadioInputConstants } from '../models/radio-input-constants';

@Injectable({
    providedIn: 'root'
})
export class RadioEditionViewUtilsService {

    constructor() { }

    // Helper method for view downcast converters.
    public createRadiusEditorView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const optionsCount = modelItem.getAttribute("optionsCount");
        const id = modelItem.getAttribute("id");

        return viewWriter.createContainerElement(
            "div",
            {
                class: [RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW],
                id,
                'data-options-count': optionsCount
            }
        );
    }

    public createOptionEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const dataPosition = modelItem.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION);

        return viewWriter.createContainerElement("div",
            {
                class: [RadioInputConstants.OPTION_CLASS_EDITION_VIEW],
                'data-position': dataPosition
            });
    }

    public createRadiusElementEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const nameGroup = modelItem.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_GROUP_NAME);

        const radioInputElement = viewWriter.createContainerElement("input",
            {
                class: [RadioInputConstants.INPUT_CLASS_EDITION_VIEW],
                'type': 'radio',
                'name': nameGroup
            });

        return radioInputElement;
    }

    public createDescriptionElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {

        return viewWriter.createEditableElement("div", {
            class: [RadioInputConstants.DESCRIPTION_CLASS_EDITION_VIEW],
        });
    }

    public createContentElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {

        return viewWriter.createEditableElement("div",
            {
                class: [RadioInputConstants.CONTENT_CLASS_EDITION_VIEW],
            });
    }
}
