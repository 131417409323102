import { Directive, EventEmitter, OnDestroy } from "@angular/core";
import { ButtonView, ContextualBalloon, Dialog, Editor, Plugin, ViewElement, Widget, clickOutsideHandler, toWidget, toWidgetEditable, viewToModelPositionOutsideModelElement, } from "ckeditor5";
import { RadioEditionViewUtilsService } from "./utils/radio-edition-view-utils.service";
import { RadioDataViewUtilsService } from "./utils/radio-data-view-utils.service";
import { RadioUtilsService } from './utils/radio-utils.service';
import AddRadioCommand from './commands/add-radio-command';
import DeleteRadioCommand from './commands/delete-radio-command';
import { AddRadioFormValidatorCallback } from "./ui/add-radio-form-view.directive";
import AddRadioFormView from "./ui/add-radio-form-view.directive";
import EditRadioFormView, { EditRadioFormValidatorCallback } from "./ui/edit-radio-form-view.directive";
import { RadioModel } from "./models/radio-model";
import AddOptionsCommand from "./commands/add-options-command";
import RemoveOptionsCommand from "./commands/remove-options-command";
import { RadioInputConstants } from "./models/radio-input-constants";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../utils/double-click-observer";
import { PluginUtilsService } from "../../utils/plugin-utils.service";

@Directive({
    selector: 'radio-plugin',
})
export class RadioPlugin extends Plugin implements OnDestroy {

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    private ATTRIBUTE_OPTIONS_POSITION = "data-position";

    private onDestroy$ = new EventEmitter<void>();
    private editorUtilsService: RadioEditionViewUtilsService;
    private dataUtilsService: RadioDataViewUtilsService;
    private utilsService: RadioUtilsService;
    private pluginUtils: PluginUtilsService;
    private balloon!: ContextualBalloon;

    private addRadioFormView: AddRadioFormView;
    private editRadioFormView: EditRadioFormView;

    private radioIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480.23-313.66q69.58 0 117.85-48.49 48.26-48.5 48.26-118.08t-48.49-117.85q-48.5-48.26-118.08-48.26t-117.85 48.49q-48.26 48.5-48.26 118.08t48.49 117.85q48.5 48.26 118.08 48.26Zm.14 189.58q-73.43 0-138.34-27.82-64.92-27.83-113.66-76.6-48.73-48.77-76.51-113.51-27.78-64.74-27.78-138.36 0-73.69 27.82-138.1 27.83-64.42 76.6-113.16 48.77-48.73 113.51-76.51 64.74-27.78 138.36-27.78 73.69 0 138.1 27.82 64.42 27.83 113.16 76.6 48.73 48.77 76.51 113.28 27.78 64.51 27.78 137.85 0 73.43-27.82 138.34-27.83 64.92-76.6 113.66-48.77 48.73-113.28 76.51-64.51 27.78-137.85 27.78Zm-.38-47.96q127.89 0 217.93-90.02 90.04-90.03 90.04-217.93 0-127.89-90.02-217.93-90.03-90.04-217.93-90.04-127.89 0-217.93 90.02-90.04 90.03-90.04 217.93 0 127.89 90.02 217.93 90.03 90.04 217.93 90.04ZM480-480Z"/></svg>';

    constructor(editor: Editor) {
        super(editor);
        this.editorUtilsService = new RadioEditionViewUtilsService();
        this.dataUtilsService = new RadioDataViewUtilsService();
        this.utilsService = new RadioUtilsService();
        this.pluginUtils = new PluginUtilsService();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public static get pluginName() {
        return "Radio" as const;
    }

    public static get pluginToolbarElementName() {
        return "radio-pg" as const;
    }

    public static get requires() {
        return [Widget, ContextualBalloon];
    }

    public static get toolbarButtonName() {
        return "radio-pg" as const;
    }

    public static get AddRadioCommandName() {
        return "add-radio-pg" as const;
    }

    public static get DeleteRadioCommandName() {
        return "delete-radio-pg" as const;
    }

    public static get AddOptionsRadioCommandName() {
        return "add-options-radio-pg" as const;
    }

    public static get RemoveOptionsRadioCommandName() {
        return "remove-options-radio-pg" as const;
    }

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
    }

    public showUI(currentId?: string, currenOptionsCount?: number, forceVisible: boolean = false): void {
        if (!this.editRadioFormView) {
            this.createEditionView(currentId, currenOptionsCount);
        }

        const radiusElement = this.utilsService.getSelectedRadiusElement(this.editor);
        // When there's no signature under the selection, go straight to the editing UI.
        if (!radiusElement) {
            // Show visual selection on a text without a link when the contextual balloon is displayed.
            // See https://github.com/ckeditor/ckeditor5/issues/4721.
            this.pluginUtils.showFakeVisualSelection(this.editor, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);

            this.addEditionFormView(currentId, currenOptionsCount);
            //this.addFormView(defaultValue);
        } else {
            this.addEditionFormView(currentId, currenOptionsCount);
        }

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        // Begin responding to ui#update once the UI is added.
        this.startUpdatingUI();
    }

    private hideFakeVisualSelection(): void {
        this.pluginUtils.hideFakeVisualSelection(this.editor, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);
    }

    private addEditionFormView(radioId?: string, optionsCount?: number): void {
        if (!this.editRadioFormView) {
            this.createEditionView(radioId, optionsCount);
        }

        if (this.isEditionFormInPanel()) {
            const isSameRadiusEditing = this.editRadioFormView.id !== radioId;
            if (isSameRadiusEditing) {
                this.editRadioFormView!.resetFormStatus();
                this.editRadioFormView.id = radioId!;
                this.editRadioFormView.optionsCount = optionsCount!;
            }
            return;
        }

        this.editRadioFormView!.resetFormStatus();
        this.editRadioFormView.id = radioId!;
        this.editRadioFormView.optionsCount = optionsCount!;

        this.balloon.add({
            view: this.editRadioFormView!,
            position: this.getBalloonPositionData()
        });

        // Select input when form view is currently visible.
        if (this.balloon.visibleView === this.editRadioFormView) {
            this.editRadioFormView!.selectDefaultField();
        }
    }

    private createAddRadioFormView(defaultValue?: number): AddRadioFormView {
        const editor = this.editor;
        const validators = this.getAddFormValidators(editor);
        const formView = new AddRadioFormView(validators, editor.locale, defaultValue);

        return formView;
    }

    private createEditionRadioFormView(radioId?: string, optionsCount?: number): EditRadioFormView {
        const editor = this.editor;
        const validators = this.getEditFormValidators(editor);
        const formView = new EditRadioFormView(validators, editor.locale, radioId, optionsCount);

        this.listenTo(formView, "cancel", () => {
            this.hideUI();
        });

        this.listenTo(formView, "submit", () => {
            const optionCountFieldView = formView.optionsCountInputView;
            const optionCountInForm = formView.optionsCount;
            const initialOptionsCount = formView.initialOptionsCount;
            const id = formView.id;

            if (optionCountInForm !== 0 && (!formView.isValid() || !optionCountInForm)) {
                return;
            }

            if (initialOptionsCount === optionCountInForm) {
                this.hideUI();
                return;
            }


            optionCountFieldView.errorText = '';

            const radioModel: RadioModel = {
                id,
                optionsCount: initialOptionsCount!,
            };

            if (initialOptionsCount! > optionCountInForm) {
                this.editor.execute(RadioPlugin.RemoveOptionsRadioCommandName, radioModel, optionCountInForm);
            } else {
                this.editor.execute(RadioPlugin.AddOptionsRadioCommandName, radioModel, optionCountInForm);
            }

            this.hideUI();
        });

        return formView;
    }

    private createEditionView(radioId?: string, optionsCount?: number) {
        //this.actionsView = this._createActionsView();
        this.editRadioFormView = this.createEditionRadioFormView(radioId, optionsCount);

        // Attach lifecycle actions to the the balloon.
        this.enableUserBalloonInteractions(this.editor, this.editRadioFormView);
    }

    private enableUserBalloonInteractions(editor: Editor, formView: EditRadioFormView | AddRadioFormView): void {
        // Focus the form if the balloon is visible and the Tab key has been pressed.
        editor.keystrokes.set('Tab', (data, cancel) => {
            if (this.areActionsVisible() && !formView!.focusTracker.isFocused) {
                formView!.focus();
                cancel();
            }
        }, {
            // Use the high priority because the signature UI navigation is more important
            // than other feature's actions, e.g. list indentation.
            priority: 'high'
        });

        // Close the panel on the Esc key press when the editable has focus and the balloon is visible.
        editor.keystrokes.set('Esc', (data, cancel) => {
            if (this.isUIVisible()) {
                this.hideUI();
                cancel();
            }
        });

        // Close on click outside of balloon panel element.
        clickOutsideHandler({
            emitter: formView!,
            activator: () => this.isUIInPanel(),
            contextElements: () => [this.balloon.view.element!],
            callback: () => this.hideUI()
        });
    }

    private areActionsVisible(): boolean {
        return !!this.editRadioFormView && this.balloon.visibleView === this.editRadioFormView;
    }

    private isUIInPanel(): boolean {
        return this.isEditionFormInPanel() || this.isAddFormInPanel() || this.areActionsInPanel();
    }

    private isEditionFormInPanel(): boolean {
        return !!this.editRadioFormView && this.balloon.hasView(this.editRadioFormView);
    }

    private isAddFormInPanel(): boolean {
        return !!this.addRadioFormView && this.balloon.hasView(this.addRadioFormView);
    }

    private areActionsInPanel(): boolean {
        const visibleView = this.balloon.visibleView;

        return !!this.editRadioFormView && visibleView == this.editRadioFormView || this.areActionsVisible();
    }

    private isUIVisible(): boolean {
        const visibleView = this.balloon.visibleView;

        return !!this.editRadioFormView && visibleView == this.editRadioFormView || this.areActionsVisible();
    }

    private enableBalloonActivators(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', () => {
            const parentRadius = this.utilsService.getSelectedRadiusElement(this.editor);

            if (!parentRadius) {
                return;
            }

            const idRadius = this.utilsService.getId(parentRadius);
            const currentOptionCount = parseInt(parentRadius?.getAttribute(RadioInputConstants.ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT)!);
            // Then show panel but keep focus inside editor editable.

            this.showUI(idRadius, currentOptionCount);
        });
    }

    private defineSchema(): void {
        const schema = this.editor.model.schema;

        schema.register(RadioInputConstants.CONTAINER_LABEL_DATA_MODEL, {
            inheritAllFrom: '$container',
            allowAttributes: ["id", RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT],
            allowContentOf: '$root',
            isObject: true
        });

        schema.register(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
            inheritAllFrom: '$container',
            allowAttributes: [RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION],
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.CONTAINER_LABEL_DATA_MODEL]
        });

        schema.register(RadioInputConstants.INPUT_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowAttributes: [RadioInputConstants.ATTRIBUTE_DATA_MODEL_GROUP_NAME],
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.OPTION_LABEL_DATA_MODEL]

        });

        schema.register(RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowContentOf: '$root',
            allowIn: [RadioInputConstants.OPTION_LABEL_DATA_MODEL],
            disallowChildren: ['imageInline', 'imageBlock', 'table']
        });

        schema.register(RadioInputConstants.CONTENT_LABEL_DATA_MODEL, {
            inheritAllFrom: '$blockObject',
            allowContentOf: '$root',
        });
    }

    private defineConverters(): void {
        this.configureConverterFromDataViewToModel();
        this.configureConverterFromModelToDataView();
        this.configureConverterFromModelToEditorView();
    }

    private configureConverterFromDataViewToModel(): void {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioInputConstants.CONTAINER_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createModelFromDataView(viewElement, writer);
            },
        });

        conversion.for('upcast').attributeToAttribute({
            view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT,
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT
        });

        conversion.for("upcast").elementToElement({
            view: {
                name: "input",
                classes: [RadioInputConstants.INPUT_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createRadioModelFromDataView(viewElement, writer);
            },
        });

        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createDescriptionModelFromDataView(viewElement, writer);
            },
        });

        conversion.for("upcast").add(dispatcher => {
            // Look for every view <div> element.
            dispatcher.on('element:div', (evt, data, conversionApi) => {
                // Get all the necessary items from the conversion API object.
                const {
                    consumable,
                    writer,
                    safeInsert,
                    convertItem,
                    convertChildren,
                    updateConversionResult
                } = conversionApi;

                // Get view item from data object.
                const { viewItem } = data;

                if (!viewItem.hasClass(RadioInputConstants.OPTION_CLASS_DATA_VIEW)) {
                    return;
                }
                // Define elements consumables.
                const optionModelToConvert = { name: true, classes: RadioInputConstants.OPTION_CLASS_DATA_VIEW };
                const inputViewElement = { name: true, classes: RadioInputConstants.INPUT_CLASS_DATA_VIEW };
                const descriptionViewElement = { name: true, classes: RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW };

                // Tests if the view element can be consumed.
                if (!consumable.test(viewItem, optionModelToConvert)) {
                    // When an element is already consumed by higher priority converters, do nothing.
                    return;
                }

                // Check if there is at least two child (description is optional)
                if (viewItem.childCount < 2) {
                    return;
                }

                // Get the first child element.
                const firstChildItem = viewItem.getChild(0);

                // Check if the first element is a input.
                if (!firstChildItem.is('element', 'input')) {
                    return;
                }

                // Tests if the first child element can be consumed.
                if (!consumable.test(firstChildItem, inputViewElement)) {
                    return;
                }

                const position = this.dataUtilsService.getPositionFromOptionView(viewItem);
                const optionModelElement = writer.createElement(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
                    'position': position
                });

                // Insert element on a current cursor location.
                if (!safeInsert(optionModelElement, data.modelCursor)) {
                    return;
                }

                // Consume the main outer wrapper element.
                consumable.consume(viewItem, optionModelToConvert);

                // Handle conversion input element.
                const inputObject = convertItem(firstChildItem, optionModelElement);
                // Consume the input element.
                consumable.consume(firstChildItem, inputViewElement);
                const secondChildItem = viewItem.getChild(1);


                // Check if the second element is a <div> with class.
                let descriptionModelElement: any;
                let descriptionObject: any;
                if (secondChildItem.is('element', 'div') && secondChildItem.hasClass(RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW)) {
                    descriptionModelElement = secondChildItem;
                    if (!consumable.test(secondChildItem, descriptionViewElement)) {
                        return;
                    }
                    //Convert Description item to model and place position after first input cursor
                    descriptionObject = convertItem(secondChildItem, inputObject.modelCursor);

                    // Consume the description element.
                    consumable.consume(secondChildItem, descriptionModelElement);
                }

                const containerElement = writer.createElement(RadioInputConstants.CONTENT_LABEL_DATA_MODEL);

                //writer.append(slot, containerElement);
                writer.append(containerElement, optionModelElement);
                convertChildren(viewItem, containerElement);
                // const rangeNextChildrenInOption = new Range();

                updateConversionResult(optionModelElement, data);
            }, { priority: 'high' });
        });
    }

    private configureConverterFromModelToDataView(): void {
        const conversion = this.editor.conversion;

        //Necesario dispatcher, cada container se transforma en un <a> con clase y un hermano div con el contenido.
        conversion.for("dataDowncast").add(downcastDispatcher => {
            downcastDispatcher.on(`insert:${RadioInputConstants.CONTAINER_LABEL_DATA_MODEL}`, (evt, data, conversionApi) => {
                // Remember to check whether the change has not been consumed yet and consume it.
                if (!conversionApi.consumable.consume(data.item, 'insert')) {
                    return;
                }

                const modelItem = data.item;
                const id = modelItem.getAttribute("id");

                // Translate the position in the model to a position in the view.
                const viewPosition = conversionApi.mapper.toViewPosition(data.range.start);
                const linkRadiusDataView = conversionApi.writer.createEmptyElement('a',
                    {
                        class: [RadioInputConstants.CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW],
                        id
                    },);
                const radiusDataView = this.dataUtilsService.createRadiusDataView(modelItem, conversionApi.writer);
                // const documentFragment = conversionApi.writer.createDocumentFragment([linkRadiusDataView, radiusDataView]);

                // Bind the newly created view element to the model element so positions will map accordingly in the future.
                conversionApi.mapper.bindElements(data.item, radiusDataView);

                // Add the newly created view element to the view.
                conversionApi.writer.insert(viewPosition, radiusDataView);
                conversionApi.writer.insert(viewPosition, linkRadiusDataView);
            });
        });

        conversion.for('dataDowncast').attributeToAttribute({
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT,
            view: RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT
        });

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.OPTION_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createOptionDataView(modelItem, writer),
        });

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.INPUT_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createRadiusElementDataView(modelItem, writer),
        });

        conversion.for("dataDowncast").elementToElement({
            model: RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => this.dataUtilsService.createDescriptionElementDataView(writer),
        });

        conversion.for("dataDowncast").elementToStructure({
            model: RadioInputConstants.CONTENT_LABEL_DATA_MODEL,
            view: (modelItem, { writer }) => writer.createSlot() //No structure for container in dataView,
        });
    }

    private configureConverterFromModelToEditorView(): void {
        const conversion = this.editor.conversion;

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.CONTAINER_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusEditorView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for('editingDowncast').attributeToAttribute({
            model: RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT,
            view: RadioInputConstants.ATTRIBUTE_EDITION_VIEW_OPTIONS_COUNT
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.OPTION_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createOptionEditionView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.INPUT_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusElementEditionView(
                    modelItem,
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionElementEditionView(
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidgetEditable(widgetElement, viewWriter);
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: RadioInputConstants.CONTENT_LABEL_DATA_MODEL,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createContentElementEditionView(
                    viewWriter
                );

                // Enable widget handling on a signature element inside the editing view.
                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private defineCommands() {
        this.editor.commands.add(
            RadioPlugin.AddRadioCommandName,
            new AddRadioCommand(this.editor)
        );
        this.editor.commands.add(
            RadioPlugin.DeleteRadioCommandName,
            new DeleteRadioCommand(this.editor)
        );

        this.editor.commands.add(
            RadioPlugin.AddOptionsRadioCommandName,
            new AddOptionsCommand(this.editor)
        );
        this.editor.commands.add(
            RadioPlugin.RemoveOptionsRadioCommandName,
            new RemoveOptionsCommand(this.editor)
        );
    }

    private defineMapper() {
        const editor = this.editor;

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.OPTION_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.INPUT_CLASS_EDITION_VIEW))
        );
        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.DESCRIPTION_CLASS_EDITION_VIEW))
        );

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass(RadioInputConstants.CONTENT_CLASS_EDITION_VIEW))
        );
    }

    private defineUI() {
        const editor = this.editor;
        const viewDocument = this.editor.editing.view.document;

        editor.editing.view.addObserver(DoubleClickObserver);
        this.balloon = editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();

        editor.ui.componentFactory.add(RadioPlugin.pluginToolbarElementName, (locale) => {
            const button = new ButtonView(locale);

            button.set({
                tooltip: 'Insertar grupo de opciones',
                withText: true,
                icon: this.radioIconSvg
            });

            button.on("execute", () => {
                const dialog = editor.plugins.get("Dialog");

                // If the button is turned on, hide the modal.
                if (button.isOn) {
                    this.closeDialog(dialog);

                    return;
                }

                button.isOn = true;

                //const defaultValue = this.SIGNATURE_ROLE_BASE + (this.utilsService.getNumSignatures(this.editor) + 1).toString();
                this.addRadioFormView = this.createAddRadioFormView();

                this.listenTo(this.addRadioFormView, "submit", () => {
                    const optionsCount = this.addRadioFormView.optionsCountInputView;

                    if (!this.addRadioFormView.isValid()) {
                        return;
                    }

                    optionsCount.errorText = '';

                    editor.execute(RadioPlugin.AddRadioCommandName, this.addRadioFormView.optionsCount);
                    this.closeDialog(dialog);
                });

                this.listenTo(this.addRadioFormView, "cancel", () => {
                    this.hideUI();
                    this.closeDialog(dialog);
                });

                dialog.show({
                    isModal: true,
                    content: this.addRadioFormView,
                    title: `Grupo de opciones`,
                    onHide() {
                        button.isOn = false;
                    },
                    id: "",
                });
            });

            return button;
        });
    }

    private getAddFormValidators(editor: Editor): Array<AddRadioFormValidatorCallback> {
        const t = editor.t;

        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber < 2) {
                    return t('Debe de tener al menos 2 opciones.');
                }

                if (optionCountNumber > 10) {
                    return t('Debe de tener máximo 10 opciones.');
                }

                return t('');
            }
        ];
    }

    private getEditFormValidators(editor: Editor): Array<EditRadioFormValidatorCallback> {
        const t = editor.t;

        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber == 1) {
                    return t('No puede existir sólo una opción.');
                }

                if (optionCountNumber > 10) {
                    return t('Debe de tener máximo 10 opciones.');
                }

                return t('');
            }
        ];
    }

    private hideUI(): void {
        if (!this.isUIInPanel) {
            return;
        }

        const editor = this.editor;

        this.stopListening(editor.ui, 'update');
        this.stopListening(this.balloon, 'change:visibleView');

        // Make sure the focus always gets back to the editable _before_ removing the focused form view.
        // Doing otherwise causes issues in some browsers. See https://github.com/ckeditor/ckeditor5-link/issues/193.
        editor.editing.view.focus();

        // Remove form first because it's on top of the stack.
        this.removeEditionFormView();
        this.removeAddFormView();

        // Then remove the actions view because it's beneath the form.
        if (this.balloon.hasView(this.editRadioFormView)) {
            this.balloon.remove(this.editRadioFormView!);
        }

        if (this.balloon.hasView(this.addRadioFormView)) {
            this.balloon.remove(this.addRadioFormView!);
        }

        this.hideFakeVisualSelection();
    }

    private closeDialog(dialog: Dialog) {
        dialog.hide();
    }

    /**
     * Removes the {@link #formView} from the {@link #_balloon}.
     */
    private removeEditionFormView(): void {
        if (!this.isEditionFormInPanel()) {
            return;
        }
        this.editRadioFormView!.resetFormStatus();

        this.balloon.remove(this.editRadioFormView!);

        this.editor.editing.view.focus();

        this.hideFakeVisualSelection();
    }

    private removeAddFormView(): void {
        if (!this.isAddFormInPanel()) {
            return;
        }
        this.addRadioFormView!.resetFormStatus();

        this.balloon.remove(this.addRadioFormView!);
        this.editor.editing.view.focus();

        this.hideFakeVisualSelection();
    }

    private getBalloonPositionData() {
        return this.pluginUtils.getBalloonPositionData(this.editor, RadioInputConstants.CONTAINER_CLASS_EDITION_VIEW, RadioInputConstants.VISUAL_SELECTION_MARKER_NAME);
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedSignature = this.utilsService.getSelectedRadiusElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedSignature = this.utilsService.getSelectedRadiusElement(this.editor);
            const selectionParent = getSelectionParent();

            // Hide the panel if:
            //
            // * the selection went out of the EXISTING signature element. E.g. user moved the caret out
            //   of the link,
            // * the selection went to a different parent when creating a NEW signature. E.g. someone
            //   else modified the document.
            // * the selection has expanded (e.g. displaying signature actions then pressing SHIFT+Right arrow).
            //
            // Note: #_getSelectedLinkElement will return a link for a non-collapsed selection only
            // when fully selected.
            if ((prevSelectedSignature && !selectedSignature) ||
                (!prevSelectedSignature && selectionParent !== prevSelectionParent)) {
                this.hideUI();
            }
            // Update the position of the panel when:
            //  * Signature panel is in the visible stack
            //  * the selection remains in the original signature element,
            //  * there was no signature element in the first place, i.e. creating a new signature
            else if (this.isUIVisible()) {
                // If still in a signature element, simply update the position of the balloon.
                // If there was no signature (e.g. inserting one), the balloon must be moved
                // to the new position in the editing view (a new native DOM range).
                this.balloon.updatePosition(this.getBalloonPositionData());
            }

            prevSelectedSignature = selectedSignature;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find((node): node is ViewElement => node.is('element'));
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }
}
