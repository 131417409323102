import { Command } from 'ckeditor5';
import { RadioModel } from '../models/radio-model';
import { RadioInputConstants } from '../models/radio-input-constants';

export default class DeleteRadioCommand extends Command {

    public override execute(value: RadioModel) {
        const editor = this.editor;

        editor.model.change(writer => {
            // Create a <signature> element with the "name" attribute (and all the selection attributes)...
            const radio = writer.createElement(RadioInputConstants.CONTAINER_LABEL_DATA_MODEL, {
                'data-options-count': value.optionsCount,
                'id': value.id
            });

            // ... and insert it into the document. Put the selection on the inserted element.
            editor.model.insertObject(radio, null, null, { setSelection: 'after' });
        });
    }

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, RadioInputConstants.CONTAINER_LABEL_DATA_MODEL);
        this.isEnabled = allowedIn !== null;
    }
}
