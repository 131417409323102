import { Directive, Inject, OnDestroy } from '@angular/core';
import {
    View, LabeledFieldView, ButtonView, icons, submitHandler, ViewCollection, FocusableView, Locale, FocusTracker, FocusCycler, KeystrokeHandler, InputNumberView, createLabeledInputNumber,
} from 'ckeditor5';

@Directive({
    selector: 'app-add-radio-form-view',
})
export default class AddRadioFormView extends View implements OnDestroy {

    public optionsCountInputView: LabeledFieldView<InputNumberView>;
    public readonly keystrokes = new KeystrokeHandler();

    public readonly focusTracker = new FocusTracker();

    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;
    private childViews: ViewCollection<LabeledFieldView<FocusableView> | ButtonView>;
    /**
     * A collection of views that can be focused in the form.
     */
    private readonly _focusables = new ViewCollection<FocusableView>();

    /**
     * Helps cycling over {@link #_focusables} in the form.
     */
    private readonly focusCycler: FocusCycler;

    private readonly validators: Array<AddRadioFormValidatorCallback>;


    constructor(
        @Inject(Array<AddRadioFormValidatorCallback>) validators: Array<AddRadioFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.validators = validators;

        this.optionsCountInputView = this.createOptionsCountInput('N.º de opciones para este grupo', optionsCount?.toString());

        const withTextInButton = true;
        this.saveButtonView = this.createButton(
            'Insertar', 'ck-button-save', icons.check, withTextInButton
        );
        // Set the type to 'submit', which will trigger
        // the submit event on entire form when clicked.
        this.saveButtonView.type = 'submit';

        this.cancelButtonView = this.createButton(
            'Cancelar', 'ck-button-cancel', icons.cancel, withTextInButton
        );

        this.cancelButtonView.delegate('execute').to(this, 'cancel');

        this.childViews = this.createCollection([
            this.optionsCountInputView,
            this.saveButtonView,
            this.cancelButtonView
        ]);

        this.focusCycler = new FocusCycler({
            focusables: this._focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                // Navigate form fields backwards using the Shift + Tab keystroke.
                focusPrevious: 'shift + tab',

                // Navigate form fields forwards using the Tab key.
                focusNext: 'tab'
            }
        });

        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-radio-form'],
                tabindex: '-1'
            },
            children: this.childViews
        });
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public override render() {
        super.render();

        // Submit the form when the user clicked the save button
        // or pressed enter in the input.
        submitHandler({
            view: this
        });

        this.childViews.forEach(v => {
            // Register the view as focusable.
            this._focusables.add(v);

            // Register the view in the focus tracker.
            this.focusTracker.add(v.element!);
        });

        // Start listening for the keystrokes coming from #element.
        this.keystrokes.listenTo(this.element!);
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            // One error per field is enough.
            if (errorText) {
                // Apply updated error.
                this.optionsCountInputView.errorText = errorText;

                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.optionsCountInputView.errorText = null;
    }

    public get optionsCount(): string | null {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return null;
        }

        return element.value.trim();
    }

    public set optionsCount(value: string | null) {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = !!value ? value : '';
    }

    public focus() {
        this.focusCycler.focusFirst();
    }

    private createOptionsCountInput(label: string, defaultValue?: string): LabeledFieldView<InputNumberView> {
        const labeledInput = new LabeledFieldView(this.locale, createLabeledInputNumber);

        labeledInput.label = label;
        labeledInput.fieldView.value = "2";
        labeledInput.fieldView.min = 2;
        labeledInput.fieldView.max = 10;

        labeledInput.fieldView.on('input', (value: any) => {
            // UX: Make the error text disappear and disable the error indicator as the user
            // starts fixing the errors.
            labeledInput.errorText = !!value ? '' : 'No puede ser nulo';
        });

        return labeledInput;
    }

    private createButton(label: string, className?: string, icon?: string, withText?: boolean): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className,
            withText: !icon || withText
        });

        return button;
    }
}

export type AddRadioFormValidatorCallback = (form: AddRadioFormView) => string | undefined;
