import { Injectable } from '@angular/core';
import { DowncastWriter, Element, ViewContainerElement, ViewElement, Writer } from 'ckeditor5';
import { RadioInputConstants } from '../models/radio-input-constants';

@Injectable({
    providedIn: 'root'
})
export class RadioDataViewUtilsService {

    constructor() { }

    public createModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        let id = viewElement.getAttribute('id');
        let optionsInViewCount = 0;
        if (!id) {
            for (const child of viewElement.getChildren()) {
                if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioInputConstants.OPTION_CLASS_DATA_VIEW)) {
                    id = this.getIdFromOptionView(id, child);
                    optionsInViewCount++;
                }
            }
        }

        let optionsCount = viewElement.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTIONS_COUNT);
        if (!optionsCount || optionsCount === '0') {
            optionsCount = optionsInViewCount.toString();
        }

        const modelElement = modelWriter.createElement(RadioInputConstants.CONTAINER_LABEL_DATA_MODEL, {
            id: id,
            'optionsCount': optionsCount

        });

        return modelElement;
    }

    public getPositionFromOptionView(optionView: ViewElement): string {

        for (const child of optionView.getChildren()) {
            if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioInputConstants.INPUT_CLASS_DATA_VIEW)) {
                const name = child.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_VIEW_GROUP_NAME);
                const id = child.getAttribute('id');
                const stringToIgnore = name + '-';
                const positionIndex = id?.indexOf(stringToIgnore)!;
                return id?.substring(positionIndex + stringToIgnore.length)!;
            }
        }

        return '0';
    }

    public createOptionModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        const position = viewElement.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_VIEW_OPTION_POSITION);
        return modelWriter.createElement(RadioInputConstants.OPTION_LABEL_DATA_MODEL, {
            'position': position,
        });
    }

    public createRadioModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        const nameGroup = viewElement.getAttribute('name');
        const element = modelWriter.createElement(RadioInputConstants.INPUT_LABEL_DATA_MODEL, {
            'dataName': nameGroup
        });
        return element;
    }

    public createDescriptionModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        const element = modelWriter.createElement(RadioInputConstants.DESCRIPTION_LABEL_DATA_MODEL, {
        });

        return element;
    }

    // Helper method for view downcast converters.
    public createRadiusDataView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const optionsCount = modelItem.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTIONS_COUNT);
        const id = modelItem.getAttribute("id");

        const mainContainerRadius = viewWriter.createContainerElement(
            "div",
            {
                class: [RadioInputConstants.CONTAINER_CLASS_DATA_VIEW],
                id,
                'data-options-count': optionsCount
            },
        );

        return mainContainerRadius;
    }

    public createOptionDataView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {

        const dataPosition = modelItem.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION);
        const optionElement = viewWriter.createContainerElement("div",
            {
                class: [RadioInputConstants.OPTION_CLASS_DATA_VIEW],
                'data-position': dataPosition
            });

        return optionElement;
    }

    public createRadiusElementDataView(modelItem: Element, viewWriter: DowncastWriter): ViewContainerElement {

        const nameGroup = modelItem.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_GROUP_NAME);
        const position = modelItem.parent!.is('element') ? modelItem.parent!.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_MODEL_OPTION_POSITION) : '0';
        const radioInputElement = viewWriter.createContainerElement("input",
            {
                class: [RadioInputConstants.INPUT_CLASS_DATA_VIEW],
                'type': 'radio',
                'name': nameGroup,
                'id': nameGroup + '-' + position,
                'value': nameGroup + '-' + position
            });

        return radioInputElement;
    }

    public createDescriptionElementDataView(viewWriter: DowncastWriter): ViewContainerElement {

        const optionElement = viewWriter.createEditableElement("div",
            {
                class: [RadioInputConstants.DESCRIPTION_CLASS_DATA_VIEW],
            });

        return optionElement;
    }

    private getIdFromOptionView(id: string | undefined, optionView: ViewElement) {
        if (id) {
            return id;
        }

        for (const child of optionView.getChildren()) {
            if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioInputConstants.INPUT_CLASS_DATA_VIEW)) {
                id = child.getAttribute(RadioInputConstants.ATTRIBUTE_DATA_VIEW_GROUP_NAME);
            }
        }

        return id;
    }

}
